import type { BiomarkerValues } from "~/utils/api/v2/results";

export function biomarkerValuesToString(values: BiomarkerValues) {
  const stringFormattedValues: Record<string, string> = {};

  for (const [key, value] of Object.entries(values)) {
    stringFormattedValues[key] = value.toFixed(2);
  }

  return stringFormattedValues;
}
