import React, { useState, useEffect } from "react";

import moment from "moment";

import resultAPI from "~/utils/api/v2/results";

type Patient = {
  id: string;
  fullname: string;
  document_type: string;
  document_number: string;
  gender: string;
  country: string;
  date_of_birth: string;
};

interface PendingResult {
  id: string;
  created_at: string;
  file_link: string;
  lab_name: string;
  patient?: Patient;
}

const ResultsList = () => {
  const [results, setResults] = useState<PendingResult[]>([]);
  const [reviewedResults, setReviewedResults] = useState<PendingResult[]>([]);
  const [activeTab, setActiveTab] = useState<"pending" | "reviewed">("pending");
  const token = typeof window !== "undefined" ? localStorage.getItem("token") : null;

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          const response = await resultAPI.listPendingResults(token);
          const sortedResults = response.data.sort((a, b) => {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
          });
          setResults(sortedResults);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    const fetchReviewedData = async () => {
      if (token) {
        try {
          const response = await resultAPI.listReviewedResults(token);
          const sortedResults = response.data.sort((a, b) => {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
          });
          setReviewedResults(sortedResults);
        } catch (error) {
          console.error("Error fetching reviewed data:", error);
        }
      }
    };

    fetchData();
    fetchReviewedData();
  }, []);

  const handleTabChange = (tab: "pending" | "reviewed") => {
    setActiveTab(tab);
  };

  return (
    <div className="max-w-screen w-full rounded overflow-hidden shadow-lg p-4">
      <div className="flex justify-between mb-4">
        <h1 className="text-xl font-bold">Resultados</h1>
        <div>
          <button
            className={`mr-2 ${activeTab === "pending" ? "font-bold" : ""}`}
            onClick={() => handleTabChange("pending")}
          >
            Pendientes
          </button>
          <button
            className={`${activeTab === "reviewed" ? "font-bold" : ""}`}
            onClick={() => handleTabChange("reviewed")}
          >
            Revisados
          </button>
        </div>
      </div>
      <ul className="w-full max-w-[900px]">
        {activeTab === "pending" &&
          results.map((result) => (
            <li
              key={result.id}
              className="w-full grid grid-cols-4 items-center border-b py-2 px-4 cursor-pointer hover:bg-gray-100"
            >
              <span className="col-span-1">{result.patient?.fullname ?? "Sin paciente"}</span>
              <span className="col-span-1">{result.patient?.document_number ?? "Sin panciente"}</span>
              <span className="col-span-1">Subido el {moment(result.created_at).format("DD-MM-YY")}</span>
              <a
                className="col-span-1"
                href={`/pending/${result.id}`}
              >
                Revisar archivo
              </a>
            </li>
          ))}
        {activeTab === "reviewed" &&
          reviewedResults.map((result) => (
            <li
              key={result.id}
              className="w-full grid grid-cols-4 items-center border-b py-2 px-4 cursor-pointer hover:bg-gray-100"
            >
              <span className="col-span-1">{result.patient?.fullname ?? "Sin paciente"}</span>
              <span className="col-span-1">{result.patient?.document_number ?? "Sin numero de identificacion"}</span>
              <span className="col-span-1">Subido el {moment(result.created_at).format("DD-MM-YY")}</span>
              <a
                className="col-span-1"
                href={`/reviewed/${result.id}`}
              >
                Ver resultado
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default ResultsList;
