import React from "react";

import ResultsList from "~/components/Results/PendingResults";

const PendingResultListPage = () => {
  return (
    <div className="w-full">
      <ResultsList />
    </div>
  );
};

export default PendingResultListPage;
